import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import SaveButton from "../../common/button/SaveButton";
import TinyEditor from "../../common/richTextEditor/TinyEditor";
import TinyPassageEditor from "../../common/richTextEditor/TinyPassageEditor";
import {
    emitErrorToast,
    emitSuccessToast,
} from "../../common/toast/ToastContainer";
import ShowQuestionList from "../../pages/question/ShowQuestionList";
const PrepareQuestionForm = () => {
    const { id, part, } = useParams();
    const location = useLocation();
    // Parse the query parameters
    const queryParams = new URLSearchParams(location.search);
    const title = queryParams.get('title');

    const navigate = useNavigate();

    const [selectedPart, setSelectedPart] = useState(parseInt(part) || 1);
    const [formValues, setFormValues] = useState({
        detail: {
            sectionA: "",
            sectionB: "",
        },
        questionText: "",
    });
    const [questionsSection1, setQuestionsSection1] = useState([]);
    const [questionsSection2, setQuestionsSection2] = useState([]);
    const [orderListSection1, setOrderListSection1] = useState([]);
    const [orderListSection2, setOrderListSection2] = useState([]);
    const [value, setValue] = useState("");

    useEffect(() => {
        getDetail();
        getListOfQuestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, selectedPart]);


    const getDetail = async () => {
        const { data } = await new APIServices(`prepare-reading-set/${id}?part=${selectedPart}`).get();
        if (data) {
            // setQuestionDataToEdit(data);

            let sectionA = "";
            let sectionB = "";

            // Extract sectionA and sectionB values from prepareQuestion's detail array if available
            if (data.prepareQuestion && data.prepareQuestion.detail && data.prepareQuestion.detail.length > 0) {
                sectionA = data.prepareQuestion?.detail[0]?.sectionA || "";
                sectionB = data.prepareQuestion?.detail[0]?.sectionB || "";
            }

            setFormValues({
                detail: {
                    sectionA,
                    sectionB,
                },
                questionText: data?.prepareQuestion?.questionText || "",
            });
        } else {
            setFormValues({
                detail: {
                    sectionA: "",
                    sectionB: "",
                },
                questionText: "",
            });
        }
    };



    const getListOfQuestions = async () => {
        const { data, success } = await new APIServices(`reading-question/get-list/${id}`).post({
            part: selectedPart
        });
        if (success) {
            // Filter the questions based on the section
            const filteredQuestionsSection1 = data.filter(question => question.section === "1");
            const filteredQuestionsSection2 = data.filter(question => question.section === "2");

            // Set the filtered questions for each section
            setQuestionsSection1(filteredQuestionsSection1);
            setQuestionsSection2(filteredQuestionsSection2);

            // Set the order list for each section
            setOrderListSection1(filteredQuestionsSection1.map(i => i?.orderBy));
            setOrderListSection2(filteredQuestionsSection2.map(i => i?.orderBy));
        }
    };


    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions;
        // Function to determine time based on selectedPart
        const getTimeForPart = (selectedPart) => {
            switch (selectedPart) {
                case 1:
                    return 11;
                case 2:
                    return 9;
                case 3:
                    return 10;
                case 4:
                    return 13;
                default:
                    return 0; // Return 0 or handle default case as needed
            }
        };

        // Get the time based on selectedPart
        const time = getTimeForPart(selectedPart);

        // Strip HTML tags from sectionA and sectionB
        const strippedSectionA = values.detail.sectionA;
        const strippedSectionB = values.detail.sectionB;

        let payload = {
            ...values,
            // detail: [values.detail], // Wrap detail in an array
            detail: [
                {
                    sectionA: strippedSectionA,
                    sectionB: strippedSectionB,
                },
            ],
            part: selectedPart,
            //need to send time
            time: time // Add time to payload
        };
        console.log("payload", payload)
        const { success, message } = await new APIServices(`prepare-reading-set/${id}`).post(payload);
        if (success) {
            emitSuccessToast(message);
            setSubmitting(false);
            resetForm();
            getDetail(); // Fetch the latest data
            getListOfQuestions();
        } else {
            emitErrorToast(message);
        }
    };

    const handlePartChange = (partNumber) => {
        setSelectedPart(partNumber);
        navigate(`/prepare-question/add/${id}/${partNumber}?title=${title}`); // Navigate to new page with selected part
    };


    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="px-4 mx-auto ">
                <h2 className="mb-6 text-xl font-medium leading-6 text-gray-900 dark:text-gray-300">
                    Prepare questions for {title}
                </h2>
                <div >
                    <div className="flex  gap-4 mb-4">

                        {/* Tabs for selecting different parts */}
                        {[1, 2, 3, 4].map(partNumber => (
                            <button
                                key={partNumber}
                                className={`inline-block px-4 py-2.5 text-sm font-medium focus:outline-none ${selectedPart === partNumber ? 'bg-blue-500 text-white' : 'text-gray-700 hover:text-gray-900'}`}
                                onClick={() => handlePartChange(partNumber)}
                            >
                                part {partNumber}
                            </button>
                        ))}
                    </div>
                    <div className="flex justify-end gap-4 mb-4" >

                        <Link
                            to={`/prepare-question/detail/${id}/${selectedPart}`}
                            className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
                        >
                            Preview Question
                        </Link>
                        <Link
                            to={`/question-response/${id}`}
                            className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
                        >
                            Start
                        </Link>
                    </div>
                </div>
                <div className="grid grid-cols-[70%,1fr] gap-4">
                    {/* Form */}
                    <div>
                        <div className="p-6 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
                            <Formik
                                initialValues={formValues}
                                onSubmit={handleFormikFormSubmit}
                                enableReinitialize
                            >
                                {(formik) => (
                                    <Form>
                                        {/* part Selection */}

                                        {/* Conditionally render detail field based on selected part */}
                                        {formik.isSubmitting && (
                                            <div className="absolute inset-0 bg-white bg-opacity-50 z-10 flex items-center justify-center">
                                                <div className="loader  text-xl font-bold">loading......</div>
                                            </div>
                                        )}
                                        <div >
                                            <div className="mb-6 relative">

                                                {/* <FormikRichTextEditor label="Text" name="questionText" /> */}
                                                <TinyPassageEditor fieldname="questionText" initialValue={formik.values.questionText} setFieldValue={formik.setFieldValue} />
                                            </div>

                                            {(selectedPart === 2) && (
                                                <div className="relative">
                                                    {/* <FormikRichTextEditorQuestion questionsSection1={questionsSection1} label="Questions for section 1" name="detail.sectionA" /> */}
                                                    <TinyEditor fieldname="detail.sectionA" initialValue={formik.values.detail.sectionA}
                                                        setFieldValue={formik.setFieldValue}
                                                        questionsSection1={questionsSection1} label="Questions for section 1"
                                                    />

                                                </div>
                                            )}
                                            {[1, 4].includes(selectedPart) && (
                                                <div className="mb-6">
                                                    {/* <FormikRichTextEditorQuestion questionsSection1={questionsSection2} label="Questions for section 2" name="detail.sectionB" /> */}
                                                    <TinyEditor fieldname="detail.sectionB" initialValue={formik.values.detail.sectionB}
                                                        setFieldValue={formik.setFieldValue}
                                                        questionsSection1={questionsSection2} label="Questions for section 2"
                                                    />
                                                </div>
                                            )}
                                            <div className="grid grid-cols-2 gap-4">
                                            </div>
                                        </div>


                                        {/* Submit */}
                                        <div className="mt-7">
                                            <div className="flex justify-start space-x-2">
                                                <SaveButton isSubmitting={formik.isSubmitting} label={("Save")} />
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>

                    {/* Question List */}
                    <div>
                        <ShowQuestionList questions={questionsSection1} orderList={orderListSection1} selectedPart={selectedPart} section={1} />
                        {selectedPart !== 3 && (
                            <div className="mt-4">
                                <ShowQuestionList questions={questionsSection2} orderList={orderListSection2} selectedPart={selectedPart} section={2} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PrepareQuestionForm;
