import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useState } from 'react';


const TinyPassageEditor = ({ fieldname, initialValue, setFieldValue, questionsSection1 }) => {
    const [value, setValue] = useState(initialValue ?? '');

    // Highlight patterns immediately upon initialization
    useEffect(() => {
        if (initialValue) {
            setValue(initialValue);
        }
    }, [initialValue]);


    const handleEditorChange = (newValue) => {
        setValue(newValue);
        setFieldValue(fieldname, newValue); // Save the highlighted content back to Formik
    };

    const handleImageUpload = (blobInfo, progress) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            const uploadUrl = process.env.REACT_APP_API_BASE_URL + "/api/file/upload";

            xhr.open("POST", uploadUrl, true);

            const formData = new FormData();
            formData.append("file", blobInfo.blob(), blobInfo.filename());

            xhr.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                    const percent = (e.loaded / e.total) * 100;
                    progress(percent); // Report progress to TinyMCE
                }
            };

            xhr.onload = () => {
                if (xhr.status === 403) {
                    reject({ message: "HTTP Error 403: Forbidden", remove: true });
                    return;
                }
                if (xhr.status === 404) {
                    reject({ message: "HTTP Error 404: Not Found", remove: true });
                    return;
                }
                if (xhr.status < 200 || xhr.status >= 300) {
                    reject("HTTP Error: " + xhr.status);
                    return;
                }

                const json = JSON.parse(xhr.responseText);

                if (!json || typeof json.location !== "string") {
                    reject("Invalid JSON response: " + xhr.responseText);
                    return;
                }

                // Resolve the image URL to insert in the editor
                resolve(json.location);
            };

            xhr.onerror = () => {
                reject({ message: "Image upload failed due to a network error", remove: true });
            };

            xhr.send(formData);
        });
    };

    return (
        <>
            <div className="grid grid-cols-2 gap-4">
                {/* TinyMCE Editor */}
                <div className="col-span-3">
                    <Editor
                        apiKey='6152nn5bugy8v76r7wuuqqz76vuez1rz3zls1htmv9flws5z'
                        value={value}
                        onEditorChange={handleEditorChange} // Handle content changes but don't save to Formik immediately
                        textareaName={fieldname}
                        init={{
                            plugins: [
                                // Core editing features
                                'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
                                // Your account includes a free trial of TinyMCE premium features
                                // Try the most popular premium features until Oct 9, 2024:
                                'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'ai', 'mentions', 'tinycomments', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown',
                            ],
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                            tinycomments_mode: 'embedded',
                            tinycomments_author: 'Author name',
                            mergetags_list: [
                                { value: 'First.Name', title: 'First Name' },
                                { value: 'Email', title: 'Email' },
                            ],
                            images_upload_handler: (blobInfo, progress) => {
                                return handleImageUpload(blobInfo, progress);
                            },
                            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),
                        }}

                    />
                </div>

            </div>
        </>
    );
};

export default TinyPassageEditor;
